import { Injectable } from '@angular/core';
import { CurrentUserPreferenceStore } from '../../core/store/current-user-preference.store';
import { ColorLabelItemDTO } from '../dto/table.dto';

@Injectable({
  providedIn: 'root',
})
export class TableConversion {
  private lang: string;

  constructor(private currentUserPreferenceStore: CurrentUserPreferenceStore) {
    this.lang = this.currentUserPreferenceStore.languageKey;
  }

  resToColordItem(item: any, key: string): ColorLabelItemDTO | null {
    if (item && key) {
      return {
        title: item[`${key}_title_${this.lang}`],
        type: item[`${key}_type`],
        color_code: item[`${key}_color_code`],
      };
    }
    return null;
  }

  resToMasterStatus(status: string): ColorLabelItemDTO | null {
    if (!status) {
      return null;
    }
    if (status == 'active') {
      return {
        title: 'Active',
        type: 'active',
        color_code: '#28A745',
      };
    } else {
      return {
        title: 'Inactive',
        type: 'inactive',
        color_code: '#E0554D',
      };
    }
  }
}
